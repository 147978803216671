import Link from "next/link";

const FooterMenu = ({ label = "", menus = [], itemClassName = "", gapClassName = "" }) => {
  return (
    <div className="jlt-w-full jlt-px-4 sm:jlt-w-1/2 md:jlt-w-1/2 lg:jlt-w-2/12 xl:jlt-w-2/12">
      <div className="jlt-mb-12 lg:jlt-mb-16">
        <h2 className="jlt-mb-10 jlt-text-xl jlt-font-bold jlt-text-black dark:jlt-text-white">
          {label}
        </h2>
        <ul className="jlt-flex jlt-flex-col jlt-gap-3">
          {menus.map((item: any, idx: number) => (
            <div key={idx} className="jlt-flex jlt-items-center jlt-gap-2">
              {item?.img_url ? (
                <div>
                  <img
                    src={item?.img_url}
                    alt={item?.img_alt || "Product Logo"}
                    width={24}
                    height={24}
                  />
                </div>
              ) : null}
              {!!item?.menu_target ? (
                <a
                  className={`jlt-inline-block jlt-text-base jlt-text-body-color jlt-duration-300 hover:jlt-text-primary dark:jlt-text-body-color-dark dark:hover:jlt-text-primary${
                    itemClassName ? ` ${itemClassName}` : ""
                  }`}
                  href={item?.menu_url || "/#"}
                  target="_blank"
                >
                  {item?.menu_label}
                </a>
              ) : (
                <Link
                  className={`jlt-inline-block jlt-text-base jlt-text-body-color jlt-duration-300 hover:jlt-text-primary dark:jlt-text-body-color-dark dark:hover:jlt-text-primary ${
                    itemClassName ? ` ${itemClassName}` : ""
                  }`}
                  href={item?.menu_url || "/#"}
                >
                  {item?.menu_label}
                </Link>
              )}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FooterMenu;
