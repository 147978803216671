import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/libs/components/CookieNotice/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider"] */ "/usr/src/app/libs/context/CartContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/libs/shared/notification/notification.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/libs/shared/popups/PopupLayout.jsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/libs/styles/wp-standard.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/app/libs/styles/fullScreenLoader.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/app/libs/styles/index.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/toastr/build/toastr.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/pixarlabs.com/src/app/registry.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/pixarlabs.com/src/components/home/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/pixarlabs.com/src/components/QueryClientProviderWrapper/QueryClientProviderWrapper.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/pixarlabs.com/src/components/SessionWrapper/SessionWrapper.jsx");
